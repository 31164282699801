import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import { handleApiError } from "../../libs/apis/handleApiError"
import { yupResolver } from "@hookform/resolvers/yup"
import bulkImg from "../../assets/images/bulk-images.png"
import { useForm } from "react-hook-form"
import Header from "../../components/Header"
import Textfield from "../../components/FormFields/Textfield"
import { isInTexas } from "../../utils/utils"

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required*"),
  emailaddress: Yup.string().email("Please enter valid email").required("Email is Required!"),
  mobile: Yup.string().matches(/^(?:[0-9]{10})?$/, "Not valid Phone number"),
  // password: Yup.string()
  //   .min(5, 'Minimum 5 characters required!!')
  //   .max(50, 'Too Long!')
  //   .required('Password is Required')
})

const pwdValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().min(5, "Minimum 5 characters required!!").max(21, "Too Long!").required("Password is Required"),
  newPassword: Yup.string().min(5, "Minimum 5 characters required!!").max(21, "Too Long!").required("Password is Required"),
  confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "New Password must match"),
})

const Profile = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang, handleProfileUpdate } = useContext(AuthContext)
  const { validLicense } = useOutletContext()
  const { openSnackbar } = useContext(SnackbarContext)
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [addressText, setAddressText] = useState("")
  const [pwdDialog, setPwdDialog] = useState({ open: false })
  const [adminDialog, setAdminDialog] = useState({ open: false })

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      emailaddress: "",
      file: null,
      addressText: "",
      mobile: "",
      license: "",
    },
  })

  const pwdForm = useForm({
    resolver: yupResolver(pwdValidationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  })

  const adminOnly =
    auth?.data?.userType == "sub-user"
      ? {
          readOnly: true,
          ref: null,
          onClick: () => setAdminDialog({ open: true }),
        }
      : { readOnly: true }

  const fetchData = async () => {
    try {
      const { data } = await api.get(`v1/salvage`)
      reset(data)
      handleProfileUpdate(data)
      setAddressText(data.addressText || "")
      setValue("isInTexas", await isInTexas(data.locationData.latitude, data.locationData.longitude))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchAutocompleteSuggestions = async (input) => {
    if (!input) {
      setSuggestions([])
      return
    }

    setLoading(true)
    try {
      const { data } = await api.get(`v1/auth/signup/autocomplete-address?input=${encodeURIComponent(input)}`)
      setSuggestions(data.predictions || []) // Handle autocomplete suggestions
      return data.predictions
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error)
      setSuggestions([])
    } finally {
      setLoading(false)
    }
  }

  const handleSelectSuggestion = async (suggestion) => {
    const placeId = suggestion.place_id

    try {
      const { data } = await api.get(`v1/auth/signup/place-details?placeId=${encodeURIComponent(placeId)}`)
      const place = data.result

      setAddressText(place.formatted_address)
      setValue("addressText", place.formatted_address, { shouldValidate: true })
      setValue("state", place.address_components.find((p) => p.types[0] === "administrative_area_level_1")?.long_name)
      setValue("city", place.address_components.find((p) => p.types[0] === "locality")?.long_name)
      setValue("postcode", place.address_components.find((p) => p.types[0] === "postal_code")?.long_name, { shouldValidate: true })
      setValue("longitude", place.geometry.location.lng)
      setValue("latitude", place.geometry.location.lat)

      setSuggestions([])
    } catch (error) {
      console.error("Error fetching place details:", error)
    }
  }

  const handleProfileSubmit = async (values) => {
    try {
      const formData = new FormData()
      const jsonString = JSON.stringify(values)
      const jsonBlob = new Blob([jsonString], { type: "application/json" })
      formData.append("body", jsonBlob)
      formData.append("profileImage", values.file)
      const { data } = await api.put(`v1/salvage`, formData)
      fetchData()
      openSnackbar({ message: "Successfully Updated", color: "success" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleChangePassword = async (values) => {
    try {
      const { data } = await api.post(`v1/salvage/reset-password-oldpassword`, { ...values, email: getValues("emailaddress") })
      fetchData()
      setPwdDialog((prev) => ({ ...prev, open: false }))
      pwdForm.reset()
      openSnackbar({ message: "Password Successfully Updated", color: "success" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  return (
    <>
      <Header title={lang.profile} />
      <div class="wrapper">
        <div class="row">
          <div class="col-md-6">
            <form onSubmit={handleSubmit(handleProfileSubmit)}>
              <div class="user-profile-img-main">
                {watch("file") ? (
                  <img src={URL.createObjectURL(watch("file"))} className="left-img" />
                ) : (
                  <img src={getValues("profileImage")?.url} className="left-img" style={{ backgroundColor: "lightgrey" }} />
                )}
                <div class="right-content">
                  <h3>Profile Photo</h3>
                  <ul>
                    <li>
                      <div class="upload-btn-wrapper">
                        <button class="btn">
                          <span>
                            <i class="fas fa-images"></i>
                          </span>{" "}
                          Upload Photo {watch("file") ? `- ${watch("file").name}` : ""}
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={(e) => {
                            setValue("file", e.target.files[0])
                            let files = e.target.files[0]
                            if (files) {
                              setValue("file", files)
                              let reader = new FileReader()
                              reader.onload = (e) => {
                                setValue("profileImage", {
                                  url: e.target.result,
                                })
                              }
                              reader.readAsDataURL(files)
                            }
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      name="firstname"
                      readOnly
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      name="lastname"
                      readOnly
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div class="form-m-b-20">
                <Textfield type="text" class="form-control" placeholder="Email" name="emailaddress" readOnly register={register} errors={errors} />
              </div>
              <div class="form-m-b-20">
                <div
                  className="form-control"
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  role="button"
                  onClick={() => {
                    setPwdDialog({ ...pwdDialog, open: true })
                  }}>
                  <div>Change Password</div>
                  <i class="fas fa-angle-right"></i>
                </div>
              </div>

              <div class="form-m-b-20">
                <Textfield
                  type="text"
                  class="form-control"
                  placeholder={lang.mobile_number + " (" + lang.optional + ")"}
                  name="mobile"
                  maxLength="10"
                  register={register}
                  errors={errors}
                />
              </div>
              <div class="form-m-b-20">
                <Textfield
                  type="text"
                  class="form-control"
                  placeholder="Company Name"
                  name="companyname"
                  register={register}
                  errors={errors}
                  {...adminOnly}
                />
              </div>
              <div class="form-m-b-20">
                <Textfield
                  type="text"
                  class="form-control"
                  placeholder="Company Phone Number"
                  name="companyphone"
                  register={register}
                  errors={errors}
                  {...adminOnly}
                />
              </div>
              <div class="form-m-b-20">
                <input
                  type="text"
                  class="form-control"
                  placeholder={lang.address}
                  value={addressText}
                  onChange={(e) => {
                    setAddressText(e.target.value)
                    fetchAutocompleteSuggestions(e.target.value)
                  }}
                  {...adminOnly}
                  readOnly={true}
                />
              </div>

              {/* Autocomplete Suggestions */}
              {loading && <div>Loading...</div>}
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion) => (
                    <li key={suggestion.place_id} onClick={() => handleSelectSuggestion(suggestion)}>
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}

              <div class="form-m-b-20">
                <Textfield
                  type="text"
                  class="form-control"
                  placeholder={lang.zipcode}
                  maxLength="5"
                  name="postcode"
                  register={register}
                  errors={errors}
                  {...adminOnly}
                  {...(auth?.data?.userType == "sub-user" ? { readOnly: true } : getValues("license") ? { readOnly: true } : { readOnly: false })}
                />
              </div>
              {!auth.data?.profiletype.includes("business") && (
                <>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="License"
                      name="license"
                      register={register}
                      errors={errors}
                      {...adminOnly}
                      {...(auth?.data?.userType == "sub-user" ? { readOnly: true } : getValues("license") ? { readOnly: true } : { readOnly: false })}
                    />
                    {watch("isInTexas") || validLicense ? (
                      <></>
                    ) : (
                      <div className="text-color-ly fw-bold">
                        {lang.email_license_docs}
                        <a href="mailto:support@liveyards.com" style={{ display: "block", color: "#52a2fe" }}>
                          support@liveyards.com
                        </a>
                      </div>
                    )}
                  </div>
                </>
              )}

              <button type="submit" class="btn btn-01 btn-width-150 mt-30">
                {lang.save}
              </button>
            </form>
          </div>
        </div>
      </div>
      {pwdDialog.open && (
        <div class="modal" role="dialog" style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                  {lang.change_password}
                </h4>
                <button type="button" class="close" style={{ border: "0" }} onClick={() => setPwdDialog({ ...pwdDialog, open: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={pwdForm.handleSubmit(handleChangePassword)}>
                <div class="modal-body">
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="Old Password"
                      name="oldPassword"
                      register={pwdForm.register}
                      errors={pwdForm.formState.errors}
                    />
                  </div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="New Password"
                      name="newPassword"
                      register={pwdForm.register}
                      errors={pwdForm.formState.errors}
                    />
                  </div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      register={pwdForm.register}
                      errors={pwdForm.formState.errors}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={() => setPwdDialog((prev) => ({ ...prev, open: false }))}>
                    {lang.cancel}
                  </button>
                  <button type="submit" class="btn btn-01 my-2" disabled={pwdForm.formState.isSubmitting}>
                    {lang.change_password}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {adminDialog.open && (
        <div class="modal" role="dialog" style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <h4 class="modal-title text-dark text-center" id="exampleModalLongTitle">
                  {lang.admin_only}
                </h4>
              </div>
              <div>
                <center>
                  <button type="button" class=" btn btn-01  mb-3" onClick={() => setAdminDialog((prev) => ({ ...prev, open: false }))}>
                    {lang.ok}
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Profile
