export async function isInTexas(latitude, longitude) {
  const url = `${process.env.REACT_APP_GOOGLE_MAPS_URL}/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

  try {
    const response = await fetch(url)
    const data = await response.json()

    if (data.status === "OK" && data.results.length > 0) {
      // Loop through the results to find administrative areas
      for (const result of data.results) {
        for (const component of result.address_components) {
          if (
            component.types.includes("administrative_area_level_1") &&
            component.short_name === "TX" // Texas state code
          ) {
            return true
          }
        }
      }
    } else {
      console.error("Geocoding failed:", data.error_message || "No results found")
    }
  } catch (error) {
    console.error("Error during reverse geocoding:", error)
  }

  return false
}
