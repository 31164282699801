import React, { useState, useEffect, useContext, useMemo } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import Header from "../../components/Header"
import DeleteDialog from "../../components/DeleteDialog"
import Dialog from "../../components/Dialog"
import InfoDialog from "../../components/InfoDialog"
import PriceDialog from "../../components/PriceDialog"
import Tooltip from "../../components/Tooltip"
import InfoAction from "../../components/InfoAction"
import moment from "moment"
import noImg from "../../assets/images/noImg.png"
import Carousal from "../../components/Carousal"
import "../../assets/css/pagination.css"
import InfiniteScroll from "react-infinite-scroll-component"
import ReportBlockDialog from "../../components/ReportBlockDialog"
import fromNowLang from "../../utils/fromNowLang"
import { isInTexas } from "../../utils/utils"

const Livefeed = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { fetchProfile, fetchLivefeed, fetchQuotes, validLicense, livefeedUnreadCounter } = useOutletContext()
  const [requests, setRequests] = useState([])
  const [deleteDialog, setDeleteDialog] = useState({ open: false, message: "" })
  const [archiveDialog, setArchiveDialog] = useState({ open: false, message: "" })
  const [infoDialog, setInfoDialog] = useState({ open: false })
  const [priceDialog, setPriceDialog] = useState({ open: false })
  const [rbDialog, setRBDialog] = useState({ open: false })
  const [imgDialog, setImgDialog] = useState()
  const [hasMore, setHasMore] = useState(true)
  const [pagination, setPagination] = useState({ page: 0, size: window.innerHeight > 1080 ? 20 : 8, totalPages: 0, pageNumList: [] })
  const fallbacks = {
    loading: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ height: "2.5em", width: "2.5em", margin: "2em 6em 2em 0" }}>
          <div className="loader"></div>
        </div>
      </div>
    ),
    empty: (
      <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 6em 2em 0", color: "gray", fontWeight: "bold" }}>
        {lang.empty_livefeed}
      </div>
    ),
    nomore: (
      <p className="text-center pt-4">
        <b>No more data to load</b>
      </p>
    ),
  }
  const [lfFallback, setLfFallback] = useState(fallbacks.loading)
  const [lessMoreId, setLessMoreId] = useState(null)

  const translate = (tid) => {
    let t = []
    requests.forEach((item) => {
      if (item.id == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setRequests(t)
  }

  const fetchData = async (p) => {
    try {
      const { data } = await fetchLivefeed(p ? p : pagination)
      let allRequests = [...requests, ...data]
      setRequests(allRequests.filter((i, ind, self) => self.findIndex((s) => s.id === i.id) == ind))
      let totalPages = Math.ceil(data.totalRecord / data.pageSize)
      let pageNumList = [] // pageNumList Not needed for Infinite Scroll
      for (let i = 1; i <= totalPages; i++) pageNumList.push(i)

      let pagn = { page: data.pageNumber, size: data.pageSize, totalPages: totalPages, pageNumList: pageNumList }
      setPagination({ ...pagination, page: pagination.page + 1 })
      setHasMore(data?.length === pagination.size)
      setLfFallback(allRequests?.length === 0 ? fallbacks.empty : data.length === 0 ? fallbacks.nomore : fallbacks.loading)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchUpdateData = async () => {
    try {
      const { data } = await fetchLivefeed({ ...pagination, page: 0, size: window.innerHeight > 1080 ? 20 : 8 })
      // const { data } = await fetchLivefeed({ ...pagination, page: 1, size: pagination.size * (pagination.page + 1) })
      setRequests(data)
      setLfFallback(requests?.length === 0 ? fallbacks.empty : data.length === 0 ? fallbacks.nomore : <></>)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`requests/${id}`)
      setDeleteDialog({ ...deleteDialog, open: false })
      setRequests(requests.filter((item) => item.id != id))
      fetchUpdateData()
      openSnackbar({ color: "success", message: "Deleted Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleArchive = async (id) => {
    try {
      const { data } = await api.put(`requests/${id}/archived`, { isArchived: true })
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchUpdateData()
      openSnackbar({ color: "success", message: "Archived Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleInfoNeeded = async (values) => {
    try {
      const { data } = await api.put(`requests/infoneeded`, { liveFeedId: values.id, question: values.message })
      setRequests((prevRequests) => prevRequests.map((item) => (item.id === values.id ? { ...item, infoNeeded: {} } : item)))
      setInfoDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Info Requested Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleInfoNeededFlag = async (id) => {
    try {
      const { data } = await api.get(`requests/updateInfoneededFlag?feedId=${id}`)
      // fetchUpdateData()
      livefeedUnreadCounter()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handlePriceIt = async (values) => {
    try {
      const { data } = await api.put(`requests/priceit`, { liveFeedId: values.id, price: values.price, description: values.message })
      setRequests((prevRequests) => prevRequests.filter((item) => item.id !== values.id))
      setPriceDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Sent Price Quote Successfully" })
      fetchQuotes("refresh")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const refreshPageData = async (p) => {
    try {
      const { data } = await fetchLivefeed(p)
      setRequests(data.liveFeedRequestDtoList.filter((item) => item.year && !item.archived))
      let totalPages = Math.ceil(data.totalRecord / data.pageSize)
      setPagination({ page: data.pageNumber, size: data.pageSize, totalPages: totalPages, pageNumList: [] })
      setLfFallback(requests?.length == 0 ? fallbacks.empty : data.liveFeedRequestDtoList.length == 0 ? fallbacks.nomore : <></>)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleReport = async (values) => {
    try {
      const { data } = await api.post(`/spam/spamFeed`, {
        feedId: values.data.id,
        reportNotes: values.reportNotes,
        reportType: values.reportType,
      })
      setRBDialog({ open: false })
      openSnackbar({ color: "success", message: "Feed Marked as Spam Successfully" })
      fetchUpdateData()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleBlock = async (values) => {
    try {
      const { data } = await api.post(`/spam/blockUser`, {
        blockedUserId: values.data.userId,
        reportNotes: values.reportNotes,
        reportType: values.reportType,
      })
      setRBDialog({ open: false })
      openSnackbar({ color: "success", message: "User Blocked Successfully" })
      fetchUpdateData()
      fetchQuotes("refresh")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const inTexas = useMemo(async () => {
    let tx = await isInTexas(auth.data.locationData.latitude, auth.data.locationData.longitude)
    return tx
  }, [auth])

  // useEffect(() => {
  //   const onFocus = () => {
  //     if (!(infoDialog.open || priceDialog.open || deleteDialog.open)) {
  //       setRequests([])
  //       setLfFallback(fallbacks.loading)
  //       fetchData({ ...pagination, page: 0, size: 8 })
  //     }
  //   }
  //   document.addEventListener("visibilitychange", onFocus)
  //   return () => {
  //     document.removeEventListener("visibilitychange", onFocus)
  //   }
  // }, [infoDialog, priceDialog, deleteDialog])

  const noApiAccess = () => {
    return auth.data.subscriptionStatus == "Expired" || validLicense == false
  }

  useEffect(() => {
    // notificationRefresh(() => fetchUpdateData())
    if (noApiAccess() == true) return
    const interval = setInterval(() => {
      fetchUpdateData()
    }, 35000)
    return () => clearInterval(interval)
  }, [requests, pagination])

  useEffect(() => {
    fetchProfile()
    if (noApiAccess() == true) return
    fetchData(pagination)
  }, [])

  return (
    <>
      <Header title={lang.livefeed} />
      <Dialog dialog={archiveDialog} />
      <DeleteDialog deleteDialog={deleteDialog} />
      <InfoDialog dialog={infoDialog} />
      <PriceDialog dialog={priceDialog} />
      <ReportBlockDialog dialog={rbDialog} />
      <div class="wrapper">
        <div class="table-main-div">
          <ul class="custome-table-head pe-5">
            <li>
              {lang.year}/{lang.make}/{lang.model}
            </li>
            <li>{lang.requests}</li>
            <li>{lang.requested_on}</li>
            <li>{lang.responses}</li>
            <li>{lang.images}</li>
            <li></li>
          </ul>
          <InfiniteScroll
            dataLength={requests?.length} //This is important field to render the next data
            height={"80vh"}
            style={{ overflowX: "hidden", paddingRight: "2em" }}
            next={() => fetchData({ ...pagination, page: pagination.page + 1 })}
            // hasMore={pagination.page - 1 == pagination.totalPages ? false : true}
            hasMore={hasMore}
            loader={lfFallback}
            endMessage={<p style={{ textAlign: "center" }}>{/* <b>No more data to load</b> */}</p>}
            // below props only if you need pull down functionality
            // refreshFunction={() => fetchData({ ...pagination, page: 0 })}
            // refreshFunction={() => {}}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
            // releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
          >
            {!requests ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ height: "2.5em", width: "2.5em", margin: "3em 0 3em 0" }}>
                  <div className="loader"></div>
                </div>
              </div>
            ) : !requests?.length ? (
              <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 0", color: "gray", fontWeight: "bold" }}>
                {lang.empty_livefeed}
              </div>
            ) : (
              <>
                {requests && (
                  <>
                    {requests?.map((item) => (
                      <div key={item.id} class="custome-tr-main">
                        <div class="icon-img-main">
                          <div>
                            <img
                              src={item.business?.profileImage?.url}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                display: !item.business?.profileImage?.url && "none",
                                backgroundColor: "lightgrey",
                              }}
                            />
                          </div>
                        </div>
                        <div class="td-1">
                          <div className="d-flex align-items-center flex-wrap">
                            <small className="text-black-50 text-break me-1">{item.business?.companyname}</small>
                            {item.business?.license && (
                              <Tooltip
                                text={lang.verfied}
                                data={<i className="fas fa-check-circle fs-6 mx-1" style={{ color: "rgb(91, 148, 229)" }}></i>}
                              />
                            )}
                            {item.distance !== undefined && <small className="text-muted ms-1">({Math.round(item.distance)} mi)</small>}
                          </div>
                          <div class="h5 m-0">
                            {item.year} {item.make}, {item.model}
                          </div>
                          <div class="text-black-50">{item.trim}</div>
                        </div>
                        <div class="td-2 d-flex flex-column align-items-start" style={{ minHeight: "4em" }}>
                          <div className="mb-auto mt-2 w-100">
                            <div className="row">
                              {lessMoreId == item.id ? (
                                <div role="button" className="col-11" onClick={() => setLessMoreId(null)}>
                                  {item.translate ? item.spanishDesc : item.desc}
                                </div>
                              ) : (
                                <div role="button" className="col-11 show-more" onClick={() => setLessMoreId(item.id)}>
                                  {item.translate ? item.spanishDesc : item.desc}
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            class="d-inline-block badge text-bg-warning text-white ml-5 text-wrap"
                            role="button"
                            onClick={() => translate(item.id)}>
                            {lang.translate}
                          </div>
                        </div>
                        <div class="td-3">{fromNowLang(moment(item.createdAt).fromNow(), lang)}</div>
                        <div class="td-4 ps-4">
                          {/* <i class='fas fa-comment-alt'></i>  */}
                          {item.responseCount}
                        </div>
                        <div class="td-5">
                          <div id="lightgallery">
                            <span style={{ cursor: "pointer" }} onClick={() => setImgDialog(item.images)}>
                              <img
                                src={item.images?.[0].url ?? noImg}
                                class="img-fluid shadow object-fit-contain border rounded"
                                style={{ width: "5em", height: "5em" }}
                              />
                            </span>
                          </div>
                        </div>
                        <div class="td-6">
                          <div class=" dot-link d-flex h6">
                            <InfoAction
                              type={item.infoNeeded?.infoAnswer ? "info-received" : item.infoNeeded ? "" : "info"}
                              flagColor={item.infoNeeded?.read ? "#58c11c" : "#f9ad4a"}
                              handleClick={() => {
                                handleInfoNeededFlag(item.id)
                                setInfoDialog({
                                  open: true,
                                  data: item,
                                  type: item.infoNeeded?.infoAnswer ? "info-received" : item.infoNeeded ? "" : "info",
                                  handleConfirm: handleInfoNeeded,
                                  handleCancel: () => setInfoDialog({ ...infoDialog, open: false }),
                                })
                              }}
                            />
                            <Tooltip
                              text={lang.price_it}
                              data={
                                <i
                                  class="fas fa-dollar-sign mx-2"
                                  style={{ cursor: "pointer", marginRight: "1.2em", fontSize: "1.2em" }}
                                  onClick={() =>
                                    setPriceDialog({
                                      open: true,
                                      type: "price-it",
                                      data: item,
                                      handleConfirm: handlePriceIt,
                                      handleCancel: () => setPriceDialog({ ...priceDialog, open: false }),
                                    })
                                  }></i>
                              }
                            />
                            <Tooltip
                              text={lang.delete}
                              data={
                                <i
                                  class="fas fa-trash mx-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setDeleteDialog({
                                      open: true,
                                      message: (
                                        <>
                                          {lang.delete_request}{" "}
                                          <b>
                                            {item.year} {item.make}, {item.model}
                                          </b>
                                          ?
                                        </>
                                      ),
                                      handleConfirm: () => handleDelete(item.id),
                                      handleCancel: () => setDeleteDialog({ ...deleteDialog, open: false }),
                                    })
                                  }></i>
                              }
                            />
                            <Tooltip
                              text={lang.report}
                              data={
                                <i
                                  class="fas fa-bars fs-5 mx-2 text-color-dark"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setRBDialog({
                                      open: true,
                                      data: item,
                                      handleReport: handleReport,
                                      handleBlock: handleBlock,
                                      handleCancel: () => setRBDialog({ ...rbDialog, open: false }),
                                    })
                                  }></i>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </InfiniteScroll>
          {/* --- Pagination Button --- */}
          {/* {requests.length > 0 && (
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li className="page-item">
                    <a
                      role="button"
                      className="page-link text-color-ly fw-bold border-0"
                      aria-label="Previous"
                      onClick={(e) => {
                        if (pagination.page > 0) fetchData({ ...pagination, page: pagination.page - 1 })
                      }}>
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  {pagination.pageNumList.map((item) => (
                    <li key={item} class="page-item">
                      <a
                        role="button"
                        className={`page-link text-color-ly fw-bold border-0 ${pagination.page + 1 == item ? "active" : ""}`}
                        onClick={(e) => {
                          fetchData({ ...pagination, page: item - 1 })
                        }}>
                        {item}
                      </a>
                    </li>
                  ))}
                  <li className="page-item">
                    <a
                      role="button"
                      className="page-link text-color-ly fw-bold border-0"
                      aria-label="Next"
                      onClick={(e) => {
                        if (pagination.page < pagination.totalPages - 1) fetchData({ ...pagination, page: pagination.page + 1 })
                      }}>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            )} */}
        </div>
        {auth.data.subscriptionStatus == "Expired" ? (
          <div className="livefeed-subscription-overlay" style={{ display: "grid" }}>
            <div className="message-box">
              <div className="d-flex align-items-end mt-3">
                <h1 className="text-color-ly fw-bold pe-2">{lang.subscribe_access_1}</h1>
                <h2>{lang.subscribe_access_2}</h2>
              </div>
              <h4 className="">{lang.subscribe_access_3}</h4>
              <button className="btn btn-01 mt-4" onClick={() => navigate("/settings/subscription")}>
                {lang.subscribe_access_btn}
                {/* <i class="fa fa-arrow-right mx-3"></i> */}
              </button>
            </div>
          </div>
        ) : validLicense == false ? (
          <div className="livefeed-subscription-overlay" style={{ display: "grid" }}>
            <div className="message-box d-flex flex-column justify-content-center align-items-center">
              <i class="fa fa-file-alt h1" style={{ fontSize: "5em" }}></i>
              <h4 className="text-color-ly text-center mt-3">{lang.license_expired_caption}</h4>
              {inTexas || validLicense ? (
                <></>
              ) : (
                <div className="text-color-ly fw-bold mt-2">
                  {lang.email_license_docs}{" "}
                  <a href="mailto:support@liveyards.com" style={{ display: "block", textAlign: "center", color: "#52a2fe" }}>
                    support@liveyards.com
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", opacity: "0.5" }}></div>
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content" style={{ overflow: "hidden" }}>
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                Image View
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0" style={{ maxHeight: "80vh" }}>
              <Carousal images={imgDialog} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Livefeed
